<template>
  <b-modal
    id="modal-user"
    :title="`${isEdit ? 'Edit' : 'Add New'} User`"
    hide-footer
    centered
    @hidden="resetModal"
  >
    <b-row>
      <b-col> Status Active </b-col>
      <b-col class="text-right">
        <b-form-group>
          <b-form-checkbox v-model="form.isActive" switch />
        </b-form-group>
      </b-col>
    </b-row>
    <validation-observer ref="form" slim>
      <validation-provider
        name="Employee Name"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group label="Employee Name *" :invalid-feedback="errors[0]">
          <custom-select
            v-model="form.fullname"
            label="fullName"
            placeholder="Search Employee"
            type="text"
            :options="employeeList"
            @input="getEmail()"
            :class="{ 'is-invalid': errors.length }"
            :reduce="({ fullName }) => fullName"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Username"
        rules="required|max:50"
        v-slot="{ errors }"
      >
        <b-form-group label="Username *" :invalid-feedback="errors[0]">
          <b-form-input
            placeholder="Enter Username"
            v-model="form.username"
            trim
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <b-form-group label="Email">
        <b-form-input
          placeholder="Enter Email"
          v-model="form.email"
          readonly
          trim
        />
      </b-form-group>
      <validation-provider name="Role" rules="required" v-slot="{ errors }">
        <b-form-group label="Role *" :invalid-feedback="errors[0]">
          <custom-select
            v-model="roleNames"
            label="roleNames"
            multiple
            placeholder="Select Role"
            type="text"
            :clearable="false"
            :class="{ 'is-invalid': errors.length }"
            :options="roleListNames"
          />
        </b-form-group>
      </validation-provider>
      <div class="d-flex justify-content-center">
        <b-button
          class="text-warning mt-2"
          variant="outline-warning"
          @click="cancel"
          block
        >
          Cancel
        </b-button>
        <b-button
          class="next ml-3"
          variant="warning"
          @click="submit"
          block
          :disabled="isBusy"
        >
          Save
        </b-button>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      default: () => ''
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    form: {
      type: Object,
      default: () => {}
    },
    employeeList: {
      type: Array,
      default: () => []
    },
    roleList: {
      type: Array,
      default: () => []
    },
    roleNames: {
      type: Array,
      default: () => []
    },
    roleListNames: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    isBusy: false,
    roles: []
  }),
  computed: {

  },
  methods: {
    async submit() {
      this.checkRoles()
      this.isBusy = true
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.isBusy = false
        return false
      }
      let employeeId = ''
      this.employeeList.map(v => {
        if (v.fullName === this.form.fullname) {
          employeeId = v.id
        }
      })
      const formData = {
        email: this.form.email,
        username: this.form.username,
        fullname: this.form.fullname,
        isActive: this.form.isActive,
        userGroups: this.roles,
        employeeId: employeeId
      }
      if (this.isEdit) {
        this.$emit('update', formData)
      } else {
        this.$emit('submit', formData)
      }
    },
    checkRoles() {
      this.roles = []
      const roleData = [...this.roleNames]
      // check if roles has retrieve from database
      if (this.form) {
        for (let i = 0; i < this.form.roles.length; i++) {
          for (let j = 0; j < this.roleNames.length; j++) {
            if (this.form.roles[i].name === this.roleNames[j]) {
              this.roles.push({
                id: this.form.roles[i].id,
                groupId: this.form.roles[i].groupId,
                userId: this.userId
              })
              if (roleData.includes(this.form.roles[i].name)) {
                const index = roleData.indexOf(this.form.roles[i].name)
                roleData.splice(index, 1)
              }
            }
          }
        }
      }

      for (let i = 0; i < roleData.length; i++) {
        for (let j = 0; j < this.roleList.length; j++) {
          if (roleData[i] === this.roleList[j].name) {
            if (this.isEdit) {
              this.roles.push({
                groupId: this.roleList[j].id,
                userId: this.userId
              })
            } else {
              this.roles.push({
                groupId: this.roleList[j].id
              })
            }
          }
        }
      }
    },
    resetModal() {
      this.$emit('reset')
      this.isBusy = false
    },
    cancel() {
      this.$bvModal.hide('modal-user')
      this.isBusy = false
    },
    getEmail() {
      if (!this.form.fullname) {
        this.form.email = ''
      } else {
        this.employeeList.find((employee) => {
          if (employee.fullName === this.form.fullname) {
            this.form.email = employee.email
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
