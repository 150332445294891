<template>
  <div>
    <b-card class="mt-3" body-class="py-4 px-3">
      <div class="mt-3 d-flex justify-content-between">
        <b-button class="next" variant="warning" @click="openModal()">
          Add New User
        </b-button>
        <b-form-group class="m-0">
          <b-input-group>
            <b-form-input
              v-model="search"
              placeholder="Search..."
              @change="$refs.table.refresh()"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <br />
      <b-row v-show="!items.length">
        <b-col>
          <div align="center">
            <img src="@/assets/icon-no-invoice.png" />
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        v-show="items.length"
        :items="fetchUsers"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        striped
        responsive
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>

        <template #cell(fullName)="{ item }">
          {{ item.fullname }}
        </template>

        <template #cell(userName)="{ item }">
          {{ item.userName }}
        </template>

        <template #cell(email)="{ item }">
          {{ item.email }}
        </template>

        <template #cell(role)="{ item }">
          <div class="role">
            {{
              item.roles ? item.roles.map((v) => v.name).join(", ") : "no data"
            }}
          </div>
        </template>

        <template #cell(isActive)="{ item }">
          {{ item.isActive ? "Active" : "Not Active" }}
        </template>

        <template #cell(action)="{ item }">
          <div class="data">
            <b-link class="text-secondary mx-2" @click="goToEdit(item)">
              <fa-icon icon="edit" />
            </b-link>
            <b-link class="text-secondary mx-2" @click="goToDelete(item.id)">
              <fa-icon icon="trash-alt" />
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="$refs.table.refresh()"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="$refs.table.refresh()"
          />
          <span class="show-entries mr-auto">
            {{
              `Show ${
                totalRows === 0 ? 0 : (currentPage - 1) * perPage + 1
              } to ${
                totalRows > currentPage * perPage
                  ? currentPage * perPage
                  : totalRows
              } of ${totalRows} entries`
            }}
          </span>
        </div>
      </div>
    </b-card>
    <modal-user
      :is-edit="isEdit"
      :form="form"
      :userId="userId"
      :employee-list="employeeList"
      :role-list="roleList"
      :role-list-names="roleListNames"
      :role-names="roleNames"
      @reset="resetForm"
      @submit="(formData) => submitUser(formData)"
      @update="(formData) => updateUser(formData)"
    />
    <modal-delete-user @remove="removeUser" />
  </div>
</template>

<script>
import api from '@/api'
import ModalUser from './ModalUser.vue'
import ModalDeleteUser from './ModalDeleteUser.vue'

export default {
  components: {
    ModalUser,
    ModalDeleteUser
  },

  data: () => ({
    employeeList: [],
    roleList: [],
    roleTable: [],
    sortBy: 'fullName',
    search: '',
    busy: false,
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      {
        key: 'no',
        label: 'No',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'fullName',
        label: 'Employee',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'username',
        label: 'Username',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'email',
        label: 'Email',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'role',
        label: 'Role',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'isActive',
        label: 'Status Active',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'action',
        label: 'Action',
        tdClass: 'text-center',
        thClass: 'text-center'
      }
    ],
    pageOptions: [10, 20, 50, 100],
    items: [],
    form: {
      id: null,
      fullname: null,
      username: null,
      email: null,
      isActive: false,
      roles: []
    },
    candidateIdDeleted: null,
    candidateIdUpdated: null,
    isEdit: false,
    userId: null,
    roleNames: [],
    roleListNames: []
  }),

  computed: {
    stringifiedFilters() {
      const str = this.search
        ? `search==${this.search}`
        : ''
      return str
    }
  },

  created() {
    this.fetchRoleOption()
    this.fetchEmployeeOption()
  },

  methods: {
    async fetchUsers() {
      const { data } = await api.user.getListUsers({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: `${this.stringifiedFilters}`,
        sorts: this.sortBy
      })
      this.items = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage
      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    async fetchRoleOption() {
      const { data } = await api.group.get()
      this.roleList = data
    },
    async fetchEmployeeOption() {
      const { data } = await api.employee.list({
        page: 1,
        pageSize: 100,
        filters: 'active',
        sorts: 'fullname'
      })
      this.employeeList = data.data
    },
    resetFilter() {
      this.filter = this.$options.data().filter
      this.$refs.table.refresh()
    },
    async submitUser(formData) {
      try {
        await api.user.save(formData)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save New User', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        setTimeout(() => {
          this.$bvModal.hide('modal-user')
          this.$refs.table.refresh()
        }, 2000)
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
      }
    },
    openModal() {
      for (let i = 0; i < this.roleList.length; i++) {
        this.roleListNames.push(this.roleList[i].name)
      }
      this.$bvModal.show('modal-user')
    },
    goToEdit(item) {
      this.candidateIdUpdated = item.id
      this.userId = item.id
      this.isEdit = true
      this.form.username = item.username
      this.form.email = item.email
      this.form.fullname = item.fullname
      this.form.isActive = item.isActive
      if (item.roles) {
        for (let i = 0; i < item.roles.length; i++) {
          this.form.roles.push({
            id: item.roles[i].id,
            name: item.roles[i].name,
            groupId: item.roles[i].groupId
          })
          this.roleNames.push(item.roles[i].name)
        }
      }
      for (let i = 0; i < this.roleList.length; i++) {
        this.roleListNames.push(this.roleList[i].name)
      }
      this.$bvModal.show('modal-user')
    },
    goToDelete(id) {
      this.candidateIdDeleted = id
      this.$bvModal.show('modal-delete-user')
    },
    async removeUser() {
      try {
        await api.user.remove(this.candidateIdDeleted)
        this.$nextTick(() => {
          this.$bvToast.toast('Successfully delete this user', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.$refs.table.refresh()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
      }
    },
    async updateUser(form) {
      form.lastLogin = null
      try {
        await api.user.put(this.candidateIdUpdated, form)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-user')
          this.$bvToast.toast('Success update user', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.$refs.table.refresh()
          setTimeout(() => {
            this.isEdit = false
          }, 1500)
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
      }
    },
    resetForm() {
      this.form = this.$options.data().form
      this.roleNames = []
      this.roleListNames = []
    }
  }
}
</script>

<style lang="scss" scoped>
.col,
.col-2,
.col-3,
.col-6 {
  padding: 0 16px;
}

.role {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
</style>
