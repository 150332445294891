<template>
  <b-modal id="modal-delete-user" hide-header hide-footer centered>
    <div class="text-center">
      <img src="@/assets/close_up.png" />
    </div>
    <h4 class="text-danger font-weight-bold text-center">
      Delete User
    </h4>
    <div class="text-center">Are you sure want to delete this data?</div>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-warning"
        variant="outline-warning"
        @click="onClickBack"
      >
        No, Cancel
      </b-button>
      <b-button class="next ml-3" variant="warning" @click="remove">
        Delete
      </b-button>
    </div>
  </b-modal>
</template>
<script>
export default {
  methods: {
    onClickBack() {
      this.$bvModal.hide('modal-delete-user')
    },
    remove() {
      this.$bvModal.hide('modal-delete-user')
      this.$emit('remove')
    }
  }
}
</script>
