var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-user","title":((_vm.isEdit ? 'Edit' : 'Add New') + " User"),"hide-footer":"","centered":""},on:{"hidden":_vm.resetModal}},[_c('b-row',[_c('b-col',[_vm._v(" Status Active ")]),_c('b-col',{staticClass:"text-right"},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.form.isActive),callback:function ($$v) {_vm.$set(_vm.form, "isActive", $$v)},expression:"form.isActive"}})],1)],1)],1),_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('validation-provider',{attrs:{"name":"Employee Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employee Name *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"fullName","placeholder":"Search Employee","type":"text","options":_vm.employeeList,"reduce":function (ref) {
	var fullName = ref.fullName;

	return fullName;
}},on:{"input":function($event){return _vm.getEmail()}},model:{value:(_vm.form.fullname),callback:function ($$v) {_vm.$set(_vm.form, "fullname", $$v)},expression:"form.fullname"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Username","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Username *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Username","trim":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)]}}])}),_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"placeholder":"Enter Email","readonly":"","trim":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Role *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"roleNames","multiple":"","placeholder":"Select Role","type":"text","clearable":false,"options":_vm.roleListNames},model:{value:(_vm.roleNames),callback:function ($$v) {_vm.roleNames=$$v},expression:"roleNames"}})],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"text-warning mt-2",attrs:{"variant":"outline-warning","block":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"next ml-3",attrs:{"variant":"warning","block":"","disabled":_vm.isBusy},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }